'use strict';
// jQuery extensions

var validator = require('./../../../app_storefront_core/cartridge/js/validator'),
    iban = require('./iban'),
    phoneCountrySelect = require('./phone-country-select'),
    regex = $.extend(true, validator.regex, {
        'email': new RegExp(Resources.REGEXP_EMAIL),
        'mobile': new RegExp(Resources.REGEXP_MOBILE),
        'password': new RegExp(Resources.REGEXP_PASSWORD),
        'nonumbers': new RegExp(Resources.REGEXP_NO_NUMBERS),
        'firstlastname': new RegExp(Resources.REGEXP_NAME),
        'nodiacriticals': new RegExp(Resources.REGEXP_NO_DIACRITICALS),
        'postalCode': {
            'uk': new RegExp(Resources.UK_POSTAL_CODE_REG_EXP),
            'bfpo': new RegExp(Resources.BFPO_NUMBER_REG_EXP)
        },
        'phone': /^(?:(?:\+?(\d)\s*(?:[-]\s*)?)?(?:\(\s*([0-9]{0,})\s*\)|([0-9]{0,}))\s*(?:[-]\s*)?)?([0-9]{0,})\s*(?:[-]\s*)?([0-9]{0,})?\s*(?:[-]\s*)?([0-9]{0,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gi,
        'IBAN': new RegExp(Resources.REGEXP_IBAN),
        'BIC': new RegExp(Resources.REGEXP_BIC)
    }),
    settings = validator.settings;

function validateEmail(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.email.test($.trim(value));
    $(element).on('focus', function(){
      removeErrorMsg(element);
    });
    return isOptional || isValid;
}

function getPostalRegExp(element) {
    var regExpValue = '';
    for (var name in regex.postalCode) {
        if($(element).hasClass(name)) {
            return regex.postalCode[name];
        }
    }
    return regExpValue;
}

function validatePostalCode(value, element) {
    var country = $(element).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
        return true;
    }
    var rgx = regex.postal[country.val().toLowerCase()];
    var isOptional = this.optional(element);
    var isValid = rgx.test($.trim(value));
    return isOptional || isValid;
}

function validatePassword(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.password.test($.trim(value));
     $(element).on('focus', function(){
      removeErrorMsg(element);
    });
    return isOptional || isValid;
}

function validateNoNumbers(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.nonumbers.test($.trim(value));
    return isOptional || isValid;
}

function validateFirstLastName(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.firstlastname.test($.trim(value));
     $(element).on('focus', function(){
      removeErrorMsg(element);
    });
    return isOptional || isValid;
}

function validateNoDiacriticals(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.nodiacriticals.test($.trim(value));
    return isOptional || isValid;
}

function validatePhone(value, element){
    if (!$(element).hasClass('required') && phoneCountrySelect.getInputWrapper().length) {
      phoneCountrySelect.initHiddenInputValues($(element));
    }

    var isOptional = this.optional(element);
    var phoneVal = $.trim(value);
    var isValid = phoneVal.length > 5 && (phoneVal).match(regex.phone);
    return isOptional || isValid;
}

/**
 * Removes the error coming from backend after a field is edited again
 */
function removeErrorMsg(field) {
  var errorContainer = $(field).next('.f-caption.error-message');
  if(errorContainer.length > 0){
    $(field).on('input', function() {
      errorContainer.hide();
    });
  }
}

/**
 * Validates length of numeric input values
 *
 * @param {String} value Input value
 * @param {HTMLElement} element Input element
 * @returns {Boolean}
 */
function validateNumericLength(value, element) {
    var isOptional = this.optional(element),
        numericStr = $.trim(value).replace(/[^0-9]/g, ""),
        minDigits = $(element).data('mindigits'),
        maxDigits = $(element).data('maxdigits'),
        isValid = minDigits ? numericStr.length >= minDigits : true;
    isValid = isValid && maxDigits ? numericStr.length <= maxDigits : isValid;
    return isOptional || isValid;
}

/**
 * Returns numeric length validation error message
 *
 * @param {Boolean} value validation value
 * @param {HTMLElement} element Input element
 * @returns {String}
 */
function getNumericLengthMessage(value, element) {
    var minDigits = $(element).data('mindigits') || '',
        maxDigits = $(element).data('maxdigits') || '';
    return $.validator.messages.rangelength(minDigits, maxDigits);
}

function validateMobile(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.mobile.test($.trim(value));
    return isOptional || isValid;
}

function $pluginFallback() {
    return $.fn;
};

function initializeEvents() {
    $(document).on('quickview.open', function() {
        validator.init();
    });
};

/**
 * Validates date inputs
 *
 * @param {String} value Input value
 * @param {HTMLElement} el Input element
 * @returns {Boolean}
 */
function validateBirthdayFields(value, el) {
    var $el = $(el),
        $form = $el.closest('form'),
        $errorMsg = $form.find('.js-date_fields-error-invalid'),
        $day = $el.hasClass('js-date_field-day') ? $el : $form.find('.js-date_field-day'),
        $month = $el.hasClass('js-date_field-month') ? $el : $form.find('.js-date_field-month'),
        $year = $el.hasClass('js-date_field-year') ? $el : $form.find('.js-date_field-year'),
        allInputs = [$day, $month, $year],
        allValuesSet = Boolean($day.val() && $month.val() && $year.val()),
        anyValueSet = Boolean($day.val() || $month.val()|| $year.val()),
        isOnSubmitCheck = this.currentElements.length !== 1;

    hideBlockViolation(allInputs, $errorMsg);

    // if customer has chosen all fields check date validity
    if (allValuesSet) {
        var dob = new Date($year.val(), $month.val() - 1, $day.val());

        if (dob.getDate() != $day.val()) {
            showBlockViolation(allInputs, $errorMsg, Resources.DATE_INVALID);

            return false;
        }

        return true;
    } else if (isOnSubmitCheck && anyValueSet) {
        showBlockViolation(allInputs, $errorMsg, Resources.DATE_INVALID);

        return value !== '';
    } else {
        return true;
    }
}

function validateBankTransferIBAN(value, el) {
	var isValid = iban.isValid( $.trim(value) );  // regex.IBAN.test($.trim(value));
	return isValid;
}

function validateBankTransferBIC(value, el) {
	var isValid = regex.BIC.test($.trim(value));
	return isValid;
}

/**
 * Toggles error message visibility for a group of inputs
 *
 * @param {Array} elements Input elements
 * @param {jQuery} $errorMsg Error message container
 * @param {String} errorMsg Error text
 */
function showBlockViolation(elements, $errorMsg, errorMsg) {
    $errorMsg
        .text(errorMsg)
		.addClass(settings.errorClass).show();

    validateElements(elements, false);
}

/**
 * Hides error message for a group of inputs
 *
 * @param {Array} elements Input elements
 * @param {jQuery} $errorMsg Error message container
 */
function hideBlockViolation(elements, $errorMsg) {
    $errorMsg.removeClass(settings.errorClass).removeClass(settings.validClass).hide();

    validateElements(elements, true);
}

/**
 * Toggles error/validity classes for a group of inputs
 *
 * @param {Array} elements Input elements
 * @param {Boolean} isValid Input validity flag
 */
function validateElements(elements, isValid) {
    for (var i = 0; i < elements.length; i++ ) {
        elements[i]
            .removeClass(isValid ? settings.errorClass : settings.validClass)
            .addClass(isValid ? settings.validClass : settings.errorClass);
    }
}

function disableFocusOutOnLoginPage() {
  if ($('.js-login-form').length) {
    settings.onfocusout = false;

    settings.onkeyup = function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    }
  }
}

module.exports = function () {
    disableFocusOutOnLoginPage()

    // params
    // toggleClass - required
    // triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
    // eventName - optional. defaults to 'click'
    $.fn.toggledList = function (options) {
        if (!options.toggleClass) { return this; }
        var list = this;
        return list.on(options.eventName || 'click', options.triggerSelector || list.children(), function (e) {
            e.preventDefault();
            var classTarget = options.triggerSelector ? $(this).parent() : $(this);
            classTarget.toggleClass(options.toggleClass);
            // execute callback if exists
            if (options.callback) {options.callback();}
        });
    };
    
    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        return this.height($(arr[arr.length - 1]).height());
    };

    /**
     * The event handling as first handler
     * @param {String} name
     * @param {Function} fn
     */
    $.fn.bindFirst = function (name, fn) {
        this.bindNth(name, fn, 0);
    };

    /**
     * The event handling on given specific position on handlers queue
     * @param {String} name
     * @param {Function} fn
     * @param {Number} index
     */
    $.fn.bindNth = function (name, fn, index) {
        // Bind event normally.
        this.bind(name, fn);
        // Move to nth position.
        this.changeEventOrder(name, index);
    };

    /**
     * Changing the order of event handler for current element
     * @param {String} names
     * @param {Number} newIndex
     */
    $.fn.changeEventOrder = function (names, newIndex) {
        var that = this;
        // Allow for multiple events.
        $.each(names.split(' '), function (idx, name) {
            that.each(function () {
                var handlers = $._data(this, 'events')[name.split('.')[0]];
                // Validate requested position.
                newIndex = Math.min(newIndex, handlers.length - 1);
                handlers.splice(newIndex, 0, handlers.pop());
            });
        });
    };

    /**
     * Getting the data attributes collection by the given prefix
     * @param  {String} prefix
     * @return {Array}
     */
    $.fn.dataByPrefix = function (prefix) {
        var data = this.data(),
            regex = new RegExp('^' + prefix),
            result = {};

        for (var key in data) {
            if(regex.test(key)) {
                result[key] = data[key];
            }
        }

        return result;
    };

    /** Setting the default options set for validator plugin */
    $.validator.setDefaults({
        ignore: ':hidden:not(.js-validate-force), .js-validate-ignore',
        highlight: function (el, errorClass, validClass) {
            var $highlightEl = getValidationHighlightElement(el, this);

            $highlightEl.addClass(errorClass).removeClass(validClass);
        },
        unhighlight: function (el, errorClass, validClass) {
            var $highlightEl = getValidationHighlightElement(el, this);

            $highlightEl.removeClass(errorClass).addClass(validClass);
        }
    });

    /**
     * Returns validation error highlight element
     *
     * @param {HTMLElement} el Element being validated
     * @param {Object} context Validator context
     * @returns {jQuery}
     */
    function getValidationHighlightElement(el, context) {
        var $el = $(el);

        if ($el.is(':radio')) {
            return context.findByName($el.attr('name'));
        }

        if (phoneCountrySelect.isHiddenInput($el)) {
            return phoneCountrySelect.getErrorContainer($el);
        }

        return $el;
    }

    /**
     * Add email validation method to jQuery validation plugin.
     * Text fields must have 'email' css class to be validated as email
     */
    $.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);

    $.validator.addMethod('postalcode', validatePostalCode, Resources.VALIDATE_POSTALCODE);

    /**
     * Add email validation method to jQuery validation plugin.
     * Text fields must have 'mobile' css class to be validated as mobile number
     */
    $.validator.addMethod('mobile', validateMobile, Resources.VALIDATE_MOBILE);

    /**
     * Add password validation method to jQuery validation plugin.
     * Text fields must have 'password' css class to be validated as password
     */
    $.validator.addMethod('password', validatePassword, Resources.VALIDATE_PASSWORD);

    $.validator.addMethod('nonumbers', validateNoNumbers, Resources.VALIDATE_NO_NUMBERS);

    $.validator.addMethod('firstLastName', validateFirstLastName, Resources.VALIDATE_NAME);

    $.validator.addMethod('nodiacriticals', validateNoDiacriticals, Resources.VALIDATE_EMAIL);

    $.validator.addMethod('phone', validatePhone, Resources.VALIDATE_MOBILE);

    $.validator.addMethod('numericrangelength', validateNumericLength, getNumericLengthMessage);

    /**
     * Add date via selectbox validation method to jQuery validation plugin.
     * Text fields must have 'js-date_fields' css class to be validated
     */
    $.validator.addMethod('js-birthday_field', validateBirthdayFields, '');

    $.validator.addMethod('isEmailEqual', function(val, el) {
        return val.toLowerCase() == $('.js-email_field').val().toLowerCase();
    }, Resources.VALIDATE_EMAIL_NOTMATCH);

    $.validator.addMethod('isPasswordEqual', function(value, element) {
    	var form = $(element).closest("form");
    	var passwordVal = form.find('input[name*="_password_"]').val();
    	return passwordVal == value;
    }, Resources.VALIDATE_PASSWORD_NOTMATCH);

    $.validator.addMethod('bankTransfer_iban', validateBankTransferIBAN, Resources.VALIDATE_IBAN);
    $.validator.addMethod('bankTransfer_bic', validateBankTransferBIC, Resources.VALIDATE_BIC);
	$.validator.addMethod('fractional_numbers', function(value, element) {
		return this.optional(element) || /^\d+(?:[\.,]?\d{1,2})?$/.test(value);
	}, $.validator.messages.number);

    $.validator.addClassRules({
        'js-emailconfirm_field': { isEmailEqual: true },
        'js-passwordconfirm_field': { isPasswordEqual: true }
    });
    /**
     * Add possibility to get required message from attribute in if it is else will be used default value
     */
    $.validator.messages.required = function($1, element, $3) {
        var requiredText = $(element).closest('.js-form_field').data('requiredText');
        return requiredText || Resources.VALIDATE_REQUIRED;
    };

    initializeEvents();

    /** Fallback to avoid application crash in case lagacy carousel plugin executed */
    $.fn.jcarousel = $pluginFallback;
    $.fn.jcarouselControl = $pluginFallback;
    $.fn.jcarouselPagination = $pluginFallback;
    $.fn.jcarouselAutoscroll = $pluginFallback;
};
