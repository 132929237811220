'use strict'

var timer = require('./../util').getTimer();

function showFlyout(animationDuration, showDuration, callback) {
    animationDuration = animationDuration || 300;
    showDuration = parseInt(showDuration) || 4000;

    var context = this;

    var $dialogWrapper = context.$container.parent();
    $dialogWrapper.addClass('hidden');
    var hiddenOffset = $(window).scrollTop() - $dialogWrapper.height() - 100;
    var topOffset = { top: hiddenOffset };
    $dialogWrapper.css(topOffset);

    var neededOffset = $dialogWrapper.offset().top;
    $dialogWrapper.css({ transition: 'top ' + (animationDuration / 1000) + 's' });

    $dialogWrapper.removeClass('hidden');

    $dialogWrapper.css({ top: neededOffset });

    var showTimeout = showDuration + animationDuration;
    timer.start(showTimeout, function() {
        $dialogWrapper.css(topOffset);
        timer.clear();
        timer.start(animationDuration, function() {
            context.close();
            $dialogWrapper.css({ transition: '' });
            timer.clear();
        });
        timer.clear();
    });

};

var makeUrl = function (url, source, productListID) {
    if (source) {
        url = require('./../util').appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = require('./../util').appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

module.exports = {
    'showFlyout' : showFlyout,
    'makeUrl' : makeUrl,
    'removeParam' : removeParam
};
