/** The references object of all modules needed for components initialization */
var references = {
	/** Page module references */
	'login' : require('./login'),
	'account' : require('./pages/account'),
	'cart' : require('./pages/cart'),
	'checkout' : require('./pages/checkout'),
	'product' : require('./pages/product'),
	'registry' : require('./pages/registry'),
	'search' : require('./pages/search'),
	'paging-processor' : require('./pages/search/paginationProcessor'),
	'wishlist' : require('./pages/wishlist'),
	'storelocator' : require('./pages/storelocator'),
	'storefront' : require('./../../../app_storefront_core/cartridge/js/pages/storefront'),
	'carousel' : require('./carousel'),
	'page' : require('./page'),
	'postmessagehandler': require('./util/postmessagehandler'),
	'customerservice': require('./pages/service/customerservice'),
	'returnform': require('./pages/returnform'),
	'findorderform': require('./pages/findorderform'),
	'newsletter': require('./pages/newsletter/index'),
	'countryselector': require('./pages/countryselector/countriesprocessor'),

	/** Core level components */
	'minicart' : require('./minicart'),
	'tooltip' : require('./tooltip'),
	'validator' : require('./../../../app_storefront_core/cartridge/js/validator'),


	/** Components from extension cartridge */
	'header' : require('./header'),
	'searchsuggest' : require('./searchsuggest'),
	'common-elements' : require('./common-elements'),
	'image': require('./pages/product/image'),
	'video': require('./pages/product/productVideo'),
	'product-actions' : require('./pages/product/product-actions'),
	'layout' : require('./layout'),
	'dialog' : require('./dialog/global'),
	'refinements': require('./pages/search/refinements'),
	'stickykit' : require('./stickykit'),
	'back-to-top' : require('./back-to-top'),
	'product-customization' : require('./product-customization'),
	'product-tile' : require('./product-tile'),
	'productsnippet' : require('./productSnippet'),
	'timer' : require('./timer'),

	/** 3rd-party integration modules */
	'gtmanalytics' : require('./analytics'),
	'recaptcha' : require('./recaptcha'),
	'phone-country-select' : require('./phone-country-select'),
	'inputpassword' : require('./inputpassword'),
};

/**
 * The components initialization configuration object
 *
 * @example New "Page" configuration
 *  var configuration = {
 *    //...
 *    'newpage' : {
 *        'enabled' : true,
 *        'options' : {},
 *        'components' : {
 *            'pagination' : {
 *                'enabled' : false,
 *                'options' : {}
 *            }
 *        }
 *    }
 *    //...
 *  }
 */
var configuration = {
	'global' : {
		'components': {
			'common-elements' : {},
			'layout' : {},
			'page' : {},
			'header' : {},
			'postmessagehandler' : {},
			'minicart' : {},
			'tooltip' : {},
			'validator' : {},
			'customerservice': {},
			'newsletter': {},
			'dialog' : {},
			'searchsuggest' : {
				'enabled' : true
			},
			'countryselector': {},
			'returnform': {
				'options' : {
					'returnForm' : '.f-return'
					}
			},
			'findorderform' : {
				'options' : {
					'findOrderForm' : '.find_order-form'
				}
			},
			'gtmanalytics' : {},
			'recaptcha' : {},
			'phone-country-select' : {},
			'inputpassword': {}
		}
	},
	'storefront': {
		'components' : {
			'timer' : {},
			'carousel' : {},
			'product-tile' : {}
		}
	},
	'cart' : {
		'components' : {
			'carousel' : {},
			'product-customization' : {},
			'product-tile' : {},
			'product-actions' : {
				'options': {
					'allowedActions': {
						'notifyme': true,
						'addtocart': true
					}
				}
			}
		}
	},
	'product' : {
		'components' : {
			'product-actions' : {
				'options': {
					'allowedActions': {
						'notifyme': true,
						'addtocart': true,
						'backinstock': true,
						'requestinfo': true,
						'storeavailability': true
					}
				}
			},
			'image' : {},
			'video' : {},
			'product-customization' : {
				'options': {
					'allowedActions': {
						'customize': true
					}
				}
			},
			'product-tile' : {},
			'productsnippet' : {}
		}
	},
	'search' : {
		'components' : {
			'refinements' : {},
			'back-to-top' : {},
			'timer' : {},
			'carousel' : {},
			'product-tile' : {},
			'paging-processor': {}
		}
	},
	'wishlist' : {
		'components' : {
			'login' : {},
			'product-actions' : {
				'options': {
					'allowedActions': {
						'notifyme': true,
						'addtocart': true
					}
				}
			}
		}
	},
	'checkout' : {
		'components' : {
			'login' : {},
			'product-customization' : {},
			'phone-country-select' : {}
		}
	},
	'storelocator' : {
		'components' : {
			'carousel' : {
				'options': {
					'autoplay': true
				}
			}
		}
		},
	'account' : {
		'components' : {
			'product-customization' : {},
			'phone-country-select' : {},
			'timer' : {}
		}
	}
};

module.exports = {
	'configuration' : configuration,
	'references' : references
};
