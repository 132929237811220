"use strict";

var $cache = {},
  selectors = {
    inputWrapper: ".f-field-password-wrapper",
    toggleButton: ".js-show-password",
  },
  types = {
    password: "password",
    text: "text",
  };

/**
 * Function for switching the password input type.
 * @function togglePasswordInputType
 * @description This function adds an event handler to the element that should switch the type of password entry. When the item is pressed, the function checks the current input type and switches it to the opposite. If the input type is not 'password' or 'text', the function does nothing.
 * @returns {void}
 */
function togglePasswordInputType($document) {
  $document.on("click", selectors.toggleButton, function () {
    var $input = $(this).closest(selectors.inputWrapper).find("input");

    if ($input && $input.length) {
      $input.attr("type", function (index, attr) {
        switch (attr) {
          case types.password:
            return types.text;
          case types.text:
            return types.password;
          default:
            return;
        }
      });
    }
  });
}

/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache() {
  $cache.document = $(document);
}

/**
 * Initializes the password type switch function.
 * @function initWindowFunction
 * @description This function adds the function'togglePasswordInputType' to the global namespace 'window' so that it can be called from anywhere in the application.
 * @returns {void}
 */
function initWindowFunction() {
  window.togglePasswordInputType = function ($document) {
    togglePasswordInputType($document);
  };
}

var inputPasswordHandler = {
  init: function () {
    initializeCache();
    togglePasswordInputType($cache.document);
    initWindowFunction();
  },
};

module.exports = inputPasswordHandler;
